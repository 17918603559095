import { initAuth, updateAuth } from "../features/auth/authSlice";
import { userRefresh } from "../features/user/userSlice";
import * as APIS from "../services/apis";
import { STORAGE_KEY } from "../utils/constants";
import { ROLE } from "../routes";
import { decodeJwt, postMessage } from "./utils";
import { store } from "@/app/store";
import { showToast } from "@/features/toast/toastSlice";

interface AccessToken {
  name: string;
  roles: string;
  sns: string;
  sub: string;
  version: string;
}

// userId 변경 여부 체크 함수
export const checkUserIdChanged = (): boolean => {
  const previousUserId = sessionStorage.getItem(STORAGE_KEY.PREVIOUS_USER_ID);
  const currentUserId = sessionStorage.getItem(STORAGE_KEY.USER_ID);

  // 이전 userId와 현재 userId가 다르면 true, 같으면 false 반환
  return previousUserId !== currentUserId;
};

export const signIn = (token?: any) => {
  let accessToken = token || sessionStorage.getItem(STORAGE_KEY.ACCESS_TOKEN);
  if (accessToken) {
    // 권한 저장
    APIS.setAuthorization(accessToken);

    const {
      name,
      roles = "NONE",
      sns,
      sub = "",
      version = "",
    } = decodeJwt(accessToken) as AccessToken;

    const ids = sub.split(":");
    const currentUserId = sub.split(":")[1];
    // 이전 userId를 세션 스토리지에 저장 (처음 로그인할 때는 이전 userId가 없을 수 있음)
    const storedUserId = sessionStorage.getItem(STORAGE_KEY.USER_ID);
    if (storedUserId) {
      sessionStorage.setItem(STORAGE_KEY.PREVIOUS_USER_ID, storedUserId);
    }

    // 현재 로그인된 userId를 세션 스토리지에 저장
    sessionStorage.setItem(STORAGE_KEY.USER_ID, currentUserId);
    sessionStorage.setItem(STORAGE_KEY.ACCESS_TOKEN, accessToken);

    let role = ROLE["NONE"];

    const splitRoles = roles.split(",");
    for (let name of splitRoles) {
      if (role?.LEVEL < ROLE[name]?.LEVEL) {
        role = ROLE[name];
      }
    }

    // userId가 변경된 경우에만 토스트 메시지 표시
    if (checkUserIdChanged()) {
      store.dispatch(
        showToast({ message: "로그인 되었습니다.", icon: "success" }),
      );
    }

    store.dispatch(
      updateAuth({
        isSigned: true,
        name,
        role,
        sns,
        socialId: +ids[0] || 0,
        userId: +ids[1] || 0,
        version,
        roles: splitRoles,
      }),
    );
    store.dispatch(userRefresh);

    /*
     * APP PUSH TOKEN 저장을 위한 로직입니다.
     * 본인인증을 한 유저만 APP PUSH TOKEN 발급 요청합니다.
     *
     * token 파라미터가 없을 경우는 이미 로그인한 회원의 자동로그인 기능이기 때문에
     * 이 떄는 중복 요청이기에 요청하지 않습니다.
     */
    if (token) {
      const param = {
        command: "SEND_TOKEN",
        data: {
          authToken: token,
          apiBaseUrl: import.meta.env.VITE_API_URL,
        },
      };
      postMessage(param);
    }

    if (token) {
      APIS.putNotificationClear();
    }
  }
};

export const signOut = () => {
  APIS.initAuthorization();
  sessionStorage.removeItem(STORAGE_KEY.ACCESS_TOKEN);
  sessionStorage.removeItem(STORAGE_KEY.USER_ID);
  sessionStorage.removeItem(STORAGE_KEY.PREVIOUS_USER_ID);
  store.dispatch(updateAuth({ isSigned: false }));
  // store.dispatch(initAuth());
};

export const saveSignupStep = (step: string) => {
  APIS.postSignUpStep(step).then(({ data: { success, message, data } }) => {
    if (success) {
      postMessage({
        command: "CUSTOM_LOG",
        param: {
          log: `${step} SUCCESS`,
        },
      });
    }
  });
};
